// Generated by Framer (f7d95e4)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import Pattern from "https://framerusercontent.com/modules/2rGdY3xNPdGAC1LGc2Ew/gQcpGdBaDKqalAQX5HN3/Pattern.js";
const PatternFonts = getFonts(Pattern);

const cycleOrder = ["c6w086azs", "iBQFnkv96", "X1O0FSHM7"];

const serializationHash = "framer-wjd5V"

const variantClassNames = {c6w086azs: "framer-v-1tst31b", iBQFnkv96: "framer-v-hknn4j", X1O0FSHM7: "framer-v-1u0x4xy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "c6w086azs", Phone: "X1O0FSHM7", Tablet: "iBQFnkv96"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "c6w086azs"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "c6w086azs", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1tst31b", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"c6w086azs"} ref={refBinding} style={{...style}} {...addPropertyOverrides({iBQFnkv96: {"data-framer-name": "Tablet"}, X1O0FSHM7: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-8dooph-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"rTb3g6HeR-container"} nodeId={"rTb3g6HeR"} rendersWithMotion scopeId={"ycy1RR2NO"}><Pattern back={"var(--token-eb13aab3-2e4e-4a60-9c64-2d76e3fe471a, rgb(255, 255, 255))"} diagonal direction={"left"} duration={5} front={"var(--token-33493b85-ff0b-4d00-af25-51f7fad547d2, rgb(250, 250, 250))"} height={"100%"} id={"rTb3g6HeR"} layoutId={"rTb3g6HeR"} patternType={"paper"} radius={0} scale={40} shouldAnimate={false} style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({X1O0FSHM7: {scale: 28}}, baseVariant, gestureVariant)}/></SmartComponentScopedContainer></ComponentViewportProvider><motion.div className={"framer-ckh020"} data-framer-name={"Gradient"} layoutDependency={layoutDependency} layoutId={"nilqWumNG"} style={{background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 10.2%, var(--token-eb13aab3-2e4e-4a60-9c64-2d76e3fe471a, rgb(255, 255, 255)) /* {\"name\":\"Background\"} */ 100%)"}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wjd5V.framer-1mtlkq3, .framer-wjd5V .framer-1mtlkq3 { display: block; }", ".framer-wjd5V.framer-1tst31b { height: 400px; overflow: hidden; position: relative; width: 800px; }", ".framer-wjd5V .framer-8dooph-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; z-index: 0; }", ".framer-wjd5V .framer-ckh020 { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; z-index: 0; }", ".framer-wjd5V.framer-v-hknn4j .framer-8dooph-container, .framer-wjd5V.framer-v-hknn4j .framer-ckh020, .framer-wjd5V.framer-v-1u0x4xy .framer-8dooph-container, .framer-wjd5V.framer-v-1u0x4xy .framer-ckh020 { top: 64px; }", ".framer-wjd5V.framer-v-1u0x4xy.framer-1tst31b { width: 400px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 400
 * @framerIntrinsicWidth 800
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"iBQFnkv96":{"layout":["fixed","fixed"]},"X1O0FSHM7":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerycy1RR2NO: React.ComponentType<Props> = withCSS(Component, css, "framer-wjd5V") as typeof Component;
export default Framerycy1RR2NO;

Framerycy1RR2NO.displayName = "Background Pattern";

Framerycy1RR2NO.defaultProps = {height: 400, width: 800};

addPropertyControls(Framerycy1RR2NO, {variant: {options: ["c6w086azs", "iBQFnkv96", "X1O0FSHM7"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerycy1RR2NO, [{explicitInter: true, fonts: []}, ...PatternFonts], {supportsExplicitInterCodegen: true})